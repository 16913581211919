/* lib imports */
import React, { useEffect, useState, useRef, useContext } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import 'boxicons';

/* component imports */
import Clues from '../components/Clues';
import CrosswordGrid from '../components/CrosswordGrid';
import PuzzleComplete from '../components/PuzzleComplete';
import CustomKeyboard from '../components/CustomKeyboard';
import MatchComplete from '../components/MatchComplete';

/* context */
import AuthContext from '../context/AuthContext';

/* url's */
import { apiUrl, rootUrl } from '../config/environment';

/* style */
import '../style/crossword.css';
import '../style/custom-keyboard.css';

/* keyboard */
const keys = [
  'Q', 'W', 'E', 'R', 'T', 'Y', 'U', 'I', 'O', 'P',
  'A', 'S', 'D', 'F', 'G', 'H', 'J', 'K', 'L',
  'Z', 'X', 'C', 'V', 'B', 'N', 'M'
];

/* exported helpers */
export function formatTime(milliseconds) {
  const minutes = Math.floor(milliseconds / 60000); 
  const seconds = Math.floor((milliseconds % 60000) / 1000); 

  return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
}

/* Play page */
function Play() {

  /* puzzleId from URL */
  const { puzzleId } = useParams();
  const [searchParams] = useSearchParams();
  const matchId = searchParams.get("matchId"); /* if multiplayer */

  /* =================== STATE VARIABLES =================== */
  /* general crossword */
  const [crossword, setCrossword] = useState(null); /* complete crossword from database */
  const [userGrid, setUserGrid] = useState(null); /* crossword displayed and modified by user */
  const [isPuzzleComplete, setIsPuzzleComplete] = useState(false); /* is puzzle complete flag */
  const [numberedCells, setNumberedCells] = useState([]); /* tuples (number, coordinate) */
  const [showLeaderboard, setShowLeaderboard] = useState(false); /* show leaderboard */
  const [ratingAdjustment, setRatingAdjustment] = useState(-2); /* skill rating to pass to leaderboard for display */
  const [showMatchResults, setShowMatchResults] = useState(false); /* multiplayer */

  /* help menu */
  const [isHelpMenuVisible, setHelpMenuVisible] = useState(false);
  const [usedHelp, setUsedHelp] = useState(false);
  const helpMenuRef = useRef(null);
  const helpButtonRef = useRef(null);
  const [menuPosition, setMenuPosition] = useState({ top: 0, left: 0 });

  /* current focus, directional vars */
  const [selectedCell, setSelectedCell] = useState({ col: -1, row: -1 }); /* default to bad vals */
  const [direction, setDirection] = useState(0); /* { 0:across, 1:down } */

  /* track consecutive backspace presses */
  const [isBackspacePressed, setIsBackspacePressed] = useState(false);

  /* do not render leaderboard until we have finished loading */
  const [submissionLoading, setSubmissionLoading] = useState(false);

  /* let the user know if they have already submitted for this puzzle */
  const [validTimeSubmit, setValidTimeSubmit] = useState(false);

  /* get the user */
  const { user } = useContext(AuthContext);

  /* defines the currently selected word */
  const [selectedWord, setSelectedWord] = useState({
    selectedWordNumber: null,
    selectedWordDirection: null,
    hint: null,
    cells: []  
  });
  
  /* timer */
  const [time, setTime] = useState(0); 
  const timerRef = useRef(null); 

  /* create history object for navigation */
  const navigate = useNavigate(); 

  /* mobile handling */
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  
  /* overflow */
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);
  
  /* =================== HELPERS =================== */
  /* fetch a crossword from our db */
  async function fetchCrossword() {
    try {

      /* get url */
      let url = `${rootUrl}/puzzles/${puzzleId}`;
      if (puzzleId === 'random') {
        url = `${rootUrl}/puzzles`;
      } 

      /* hit the crossword endpoint to get a random crossword */
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();

      /* set the first item from fetched array as crossword data */
      setCrossword(data); 

      /* create a new grid based on the crossword */
      const blankGrid = data.grid.map(row => row.map(cell => cell === '*' ? '*' : ''));
      setUserGrid(blankGrid);
    
    /* failed to fetch crossword */
    } catch (error) {
      console.error("Could not fetch crossword", error);
    }
  }

  /* 2) New helper to finish a multiplayer match */
  const finishMultiplayerMatch = async () => {
    try {
        const finalTime = usedHelp ? 9999999 : time;
  
        const response = await fetch(`${apiUrl}/multiplayer/match/finish`, {
          method: 'POST',
          headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('authToken')}`
          },               
          body: JSON.stringify({
              matchUuid: matchId,
              username: user.username,
              time: finalTime
            })
        });
  
        if (!response.ok) {
          throw new Error('Error finishing multiplayer match');
        }

        console.log("Multiplayer finish submitted successfully.");
  
        /* results page? */
        /* setShowResults(true) */
  
      } catch (error) {
        console.error('Error finishing multiplayer match:', error);
      }
  };

  /* reveals the selected cell */
  const revealSquare = () => {

    /* help was used */
    setUsedHelp(true)

    /* validitiy check */
    if (selectedCell.x >= 0 && selectedCell.y >= 0) {

      /* new copy */
      const newUserGrid = userGrid.map(row => [...row]);
  
      /* update particular cell */
      newUserGrid[selectedCell.y][selectedCell.x] = crossword.grid[selectedCell.y][selectedCell.x];
      
      /* set new changes */
      setUserGrid(newUserGrid);
  
    } else {
      console.log("Selected cell is invalid:", selectedCell);
    }
  
    /* close help menu */
    toggleHelpMenu();
  };

  /* reveal selected word */
  const revealWord = () => {

    /* help was used */
    setUsedHelp(true)

    /* validity check */
    if (selectedWord.cells.length > 0) {

      /* new copy */
      const newUserGrid = userGrid.map(row => [...row]);
  
      /* update affected cells */
      selectedWord.cells.forEach(cell => {
        newUserGrid[cell.y][cell.x] = cell.char;
      });
  
      /* set new changes */
      setUserGrid(newUserGrid);
  
    } else {
      console.log("No cells in selectedWord to reveal.");
    }
  
    /* close help menu */
    toggleHelpMenu();
  };  

  /* reveal puzzle */
  const revealPuzzle = () => {

    /* help was used */
    setUsedHelp(true)

    /* provide the solution */
    setUserGrid(crossword.grid)

    /* close help menu */
    toggleHelpMenu();
  }

  /* toggle menu visibility */
  const toggleHelpMenu = () => {
    /* get position for menu */
    if (!isHelpMenuVisible) {
      const rect = helpButtonRef.current.getBoundingClientRect();
      setMenuPosition({ top: (rect.bottom - 10) + window.scrollY, left: rect.left + window.scrollX });
    }
    setHelpMenuVisible(prev => !prev);
  };
  
  /* post an entry to the leaderboard */
  const postLeaderboardEntry = async () => {
    try {

      /* begin to load */
      setSubmissionLoading(true);

      /* adjust time to an arbitrarily high value if help was used */
      const finalTime = usedHelp ? 9999999 : time;

      const response = await fetch(`${apiUrl}/leaderboard/add-entry`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`
        },
        body: JSON.stringify({
          userId: user._id,
          puzzleId: crossword._id,
          completionTime: finalTime
        })
      });

      /* error handling */
      if (!response.ok) {
        if (response.status === 409) {
          setRatingAdjustment(-1); /* invalid adjustment */
          setValidTimeSubmit(false); /* invalid submit */
        }
        throw new Error('Failed to post leaderboard entry');
      } else {
        setValidTimeSubmit(true);
      }

    } catch (error) {
      /* ensure we leave state unchanged */
      console.error('Error posting to leaderboard:', error);

    } finally {
      /* done loading */
      setSubmissionLoading(false);
    }
  };

  /* update the number of plays for a puzzle */
  const updatePuzzleAttributes = async (completionTime) => {
    try {
      const response = await fetch(`${apiUrl}/puzzle/updateCompletionStats`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`
        },
        body: JSON.stringify({
          puzzleId: crossword._id,
          completionTime
        })
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
    } catch (error) {
      console.error('Error updating number of plays:', error);
    }
  };

  /* update the user's recent games */
  const updateRecentGames = async () => {

    try {
      const response = await fetch(`${apiUrl}/user/updateRecentGames`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`
        },
        body: JSON.stringify({
          userId: user._id,
          puzzleId: crossword._id,
          completionTime: time,
          skillRatingAdjustment: ratingAdjustment,
          usedHelp: usedHelp
        })
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
    } catch (error) {
      console.error('Error updating recent games:', error);
    }
  };  

  /* adjust skill rating of given user */
  const updateSkillRating = async () => {

    /* get percentile to update skill rating */
    const fetchPercentile = async (puzzleId, completionTime) => {
      try {
        const response = await fetch(`${apiUrl}/leaderboard/calculate-percentile`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('authToken')}`
          },
          body: JSON.stringify({ puzzleId, completionTime })
        });
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        return data.percentile;
      } catch (error) {
        console.error('Error fetching percentile:', error);
        return null; 
      }
    };

    /* adjustment */
    let adjustment;

    /* help used, accept penalty */
    if (usedHelp) {
      adjustment = -20;
    }

    /* first play, auto +20 SR */
    else if (crossword.numberOfPlays === 0) {
      adjustment = 20;
    }

    /* less than 10 plays, based on time and average so far */
    else if (crossword.numberOfPlays < 10) {
        if (time <= crossword.averageCompletionTime) {
            adjustment = 20;
        } else if (time > 150000) {
            adjustment = -10;
        }
        else {
            adjustment = 10;
        }

    /* more than 10 plays, rank based on percentile */
    } else {

        const percentile = await fetchPercentile(puzzleId, time);
        if (percentile === null) {
            console.error('Failed to fetch percentile, skill rating not updated');
            return;
        }

        /* adjustment based on percentile */
        if (percentile >= 90) {
            adjustment = 30;
        } else if (percentile >= 75) {
            adjustment = 20;
        } else if (percentile >= 50) {
            adjustment = 10;
        } else if (percentile >= 25) {
            adjustment = 0;
        } else {
            adjustment = -20;
        }
    }

    /* new skill rating */
    let adjustedSkillRating = user.skillRating + adjustment;
    setRatingAdjustment(adjustment)
    
    /* endpoint for skill rating adjustment */
    try {
      const response = await fetch(`${apiUrl}/user/updateSkillRating`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`
        },
        body: JSON.stringify({
          userId: user._id,
          skillRating: adjustedSkillRating
        })
      });

      if (!response.ok) {
        const errorData = await response.json();
        console.error(`HTTP error! status: ${response.status}`, errorData.logs);
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
    } catch (error) {
      console.error('Error updating skill rating');
    }
  };

  /* returns words that match current x, y coord */
  function findRelevantWord(words, x, y) {

    /* filter words that include current x, y */
    let matchingWords = words.filter(word =>
      word.cells.some(cell => cell.x === x && cell.y === y)
    );
  
    /* only one matching word, return */
    if (matchingWords.length === 1) {
      /* update direction for consistency and return */
      setDirection(matchingWords[0].direction)
      return matchingWords[0];
    /* crossroad between across and down, select based on direction */
    } else {
      return matchingWords.find(word => word.direction === direction);
    }
  }

  /* starts the timer */
  const startTimer = () => {
    const startTime = Date.now();

    timerRef.current = setInterval(() => {
      setTime(Date.now() - startTime); 
    }, 10); 
  };

  /* stops the timer */
  const stopTimer = () => {
    if (timerRef.current) {
      clearInterval(timerRef.current);
    }
  };

  /* focus the next word (currently selected + 1) */
  const focusNextWord = () => {
    if (!selectedWord) return;

    /* list of across, down words */
    const { acrossWords, downWords } = crossword.words.reduce(
      (acc, word) => {
        if (word.direction === 0) {
          acc.acrossWords.push(word);
        } else if (word.direction === 1) {
          acc.downWords.push(word);
        }
        return acc;
      },
      { acrossWords: [], downWords: [] }
    );
  
    /* sort by number */
    acrossWords.sort((a, b) => a.number - b.number);
    downWords.sort((a, b) => a.number - b.number);
  
    /* get currently selected words */
    const currentDirectionWords = selectedWord.selectedWordDirection === 0 ? acrossWords : downWords;
  
    /* get current index of selected word */
    const currentWordIndex = currentDirectionWords.findIndex(word => word.number === selectedWord.selectedWordNumber);
    if (currentWordIndex === -1) return;

    /* if this is the last in the list, switch to the next list */
    if (currentWordIndex + 1 >= currentDirectionWords.length) {
     
      /* get information of the first word in the other direction */
      const otherDirectionWords = selectedWord.selectedWordDirection === 0 ? downWords : acrossWords;
      if (otherDirectionWords.length > 0) {
        const nextWord = otherDirectionWords[0];
        setDirection(prevDirection => 1 - prevDirection);
        handleCellFocus(nextWord.cells[0].x, nextWord.cells[0].y)
        setSelectedCell({ x: nextWord.cells[0].x, y: nextWord.cells[0].y });
        focusCell(nextWord.cells[0].y, nextWord.cells[0].x);
      }

    } else {

      /* get information of the next word */
      const nextWordIndex = (currentWordIndex + 1) % currentDirectionWords.length;
      const nextWord = currentDirectionWords[nextWordIndex];
      const nextWordFirstCell = nextWord.cells[0];

      /* set focus */
      handleCellFocus(nextWordFirstCell.x, nextWordFirstCell.y);
      setSelectedCell({x: nextWordFirstCell.x,  y : nextWordFirstCell.y });
      focusCell(nextWordFirstCell.y , nextWordFirstCell.x);
    }
  };

  /* compares 2d arrays for equivalence */
  function arraysEqual(arr1, arr2) {
    for (let i = 0; i < arr1.length; i++) {
      for (let j = 0; j < arr2.length; j++) {
        if (arr1[i][j] !== arr2[i][j]) return false;
      }
    }
    return true;
  }

  /* returns a list of tuples (number, coordinate) of cells that are numbered */
  function getNumberedCells() {

    /* maintain numbered cells */
    const numberedCells = [];

    /* iterate over our words */
    for (const word of crossword.words) {
      /* get the cell that requires a number (first elem) */
      const cell = word.cells[0];
      /* add to the list */
      numberedCells.push([word.number, cell]);
    }

    /* return */
    return numberedCells;
  }

  /* handle arrow key navigation with advanced wrapping and skipping over blocked cells */
  const onArrowClick = (arrowDirection) => {
    const numRows = crossword.grid.length;
    const numCols = crossword.grid[0].length;
    let nextRow = selectedCell.y;
    let nextCol = selectedCell.x;
    let attempts = 0;

    const incrementIndex = (index, max, direction) => {
      if (direction === 'increase') {
        return (index + 1) % max;
      } else {
        return (index - 1 + max) % max;
      }
    };

    while (attempts < numRows * numCols) {  // Prevent infinite loops
      switch (arrowDirection) {
        case 'ArrowRight':
          nextCol = incrementIndex(nextCol, numCols, 'increase');
          break;
        case 'ArrowLeft':
          nextCol = incrementIndex(nextCol, numCols, 'decrease');
          break;
        case 'ArrowDown':
          nextRow = incrementIndex(nextRow, numRows, 'increase');
          break;
        case 'ArrowUp':
          nextRow = incrementIndex(nextRow, numRows, 'decrease');
          break;
        default:
          console.log("Unsupported arrow direction");
          return;
      }

      // Check if the new position is valid
      if (userGrid[nextRow][nextCol] !== '*') {
        setSelectedCell({ x: nextCol, y: nextRow });
        focusCell(nextRow, nextCol);
        return;
      }

      // Increment the attempts counter
      attempts += 1;
    }
  };

  /* handle auto moving focus when typing consecutive letters */
  const onNextCell = (row, col) => {
    /* init with our current position */
    let nextRow = row;
    let nextCol = col;
    /* across */
    if (direction === 0) { 
      if (col + 1 < crossword.grid[row].length) {
        nextCol = col + 1;
      }
    /* down */
    } else {
      if (row + 1 < crossword.grid.length) {
        nextRow = row + 1;
      }
    }

    /* set selected cell and focus */
    if ((direction === 0 && userGrid[row][nextCol] !== '*') || (direction === 1 && userGrid[nextRow][col] !== '*')) {
      setSelectedCell({ x: nextCol, y: nextRow });
      focusCell(nextRow, nextCol);
    }
  };

  /* handle auto moving focus when typing consecutive letters */
  const onPrevCell = (row, col) => {
    /* init with our current position */
    let prevRow = row;
    let prevCol = col;
    /* across */
    if (direction === 0) { 
        if (col - 1 >= 0) {
            prevCol = col - 1;
        }
    /* down */
    } else { 
        if (row - 1 >= 0) {
            prevRow = row - 1;
        }
    }
    
    /* set selected cell and focus */
    if ((direction === 0 && userGrid[row][prevCol] !== '*') || (direction === 1 && userGrid[prevRow][col] !== '*')) {
      setSelectedCell({ x: prevCol, y: prevRow });
      focusCell(prevRow, prevCol);
    }
  };

  /* handle keydown events including direction swap with Shift key */
  const handleKeyDown = (e, rowIndex, colIndex) => {

    /* prevent default */
    if (['Backspace', 'Tab', ' ', 'ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown', 'Shift', 'Enter'].includes(e.key) && !isMobile) {
      e.preventDefault();
    }
  
    /* backspace logic */
    if (e.key === 'Backspace') {
      /* force prevCell if empty */
      if (userGrid[rowIndex][colIndex] === ' ' || userGrid[rowIndex][colIndex] === '') {
        onPrevCell(rowIndex, colIndex);
        setIsBackspacePressed(true);
      }
      /* not the first backspace pressed */
      else if (isBackspacePressed) {
        onPrevCell(rowIndex, colIndex);
      } else {
        setIsBackspacePressed(true);
      }
      /* update to empty */
      handleCellUpdate(rowIndex, colIndex, '');
    } else {
      setIsBackspacePressed(false);
    }
  
    /* tab navigation */
    if (e.key === 'Tab' || e.key === 'Enter') {
      focusNextWord();
    }
    /* space bar entry */
    else if (e.key === ' ') {
      /* update cell with space */
      handleCellUpdate(rowIndex, colIndex, ' ');
      onNextCell(rowIndex, colIndex);
    }
    /* arrow key handling */
    else if (['ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown'].includes(e.key)) {
      onArrowClick(e.key);
    }
    /* shift key toggles direction */
    else if (e.key === 'Shift') {
      const newDirection = direction === 0 ? 1 : 0;
      setDirection(newDirection);
    }
    /* insert value if on mobile */
    else if (e.key.length === 1 && /^[a-zA-Z]$/.test(e.key) && isMobile) {
      handleCellUpdate(rowIndex, colIndex, e.key.toUpperCase()); 
      onNextCell(rowIndex, colIndex);
    }
  };

  /* refocus to a particular cell */
  const focusCell = (row, col) => {
    const cellElement = document.querySelector(`[data-row='${row}'][data-col='${col}']`);
    if (cellElement) {
      cellElement.focus();
    }
  };

  /* =================== USEEFFECTS =================== */
  /* when we mount */
  useEffect(() => {

    /* fetch crossword and set first word */
    fetchCrossword();

    /* start the timer */
    startTimer();

    /* on unmounting, clean up */
    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    };

  }, []); /* on mount */
  
  /* when focus is changed */
  useEffect(() => {
    /* do not run on mount */
    if (selectedCell.col === -1) {
      return
    }

    /* get the new word */
    var newSelection = findRelevantWord(crossword.words, selectedCell.x, selectedCell.y)

    /* potentially at an invalid square here, exit if we find invalid word */
    if (!newSelection) {
      return
    }

    /* update our selectedWord */
    setSelectedWord({ 
      selectedWordNumber: newSelection.number, 
      selectedWordDirection: newSelection.direction,
      hint: newSelection.hint,
      cells: newSelection.cells
    });
  }, [selectedCell]);

  /* when crossword is changed */
  useEffect(() => {
    /* ignore on mount */
    if (!crossword) {
      return
    }

    /* get numbered cells */
    setNumberedCells(getNumberedCells())

    /* set focus to the first word if we just got here */
    if (crossword && selectedWord.cells.length === 0 && selectedWord.selectedWordNumber === null) {
      const firstWord = crossword.words.find(word => word.number === 1);
      if (firstWord) {
        setSelectedWord({
          selectedWordNumber: firstWord.number,
          selectedWordDirection: firstWord.direction,
          hint: firstWord.hint,
          cells: firstWord.cells
        });
        handleCellFocus(firstWord.cells[0].x, firstWord.cells[0].y);
        setSelectedCell({x: firstWord.cells[0].x, y: firstWord.cells[0].y});
        focusCell(firstWord.cells[0].y, firstWord.cells[0].x);
      }
    }
  }, [crossword])

  /* when userGrid is changed */
  useEffect(() => {
    /* ignore if our dependents are not yet loaded */
    if (!crossword || !userGrid) {
      return
    }

    /* check if the userGrid is complete */
    if (arraysEqual(crossword.grid, userGrid)) {
      setIsPuzzleComplete(true);
    } 
  }, [userGrid])

  /* when the puzzle is complete */
  useEffect(() => {
    if (isPuzzleComplete) {
      stopTimer();
      /* multiplayer */
      if (matchId) {
        finishMultiplayerMatch();
        setShowMatchResults(true);
        console.log("Submitted to match, set show results to true")
      /* single player */
      } else {
        postLeaderboardEntry();
        updatePuzzleAttributes(time);
        setShowLeaderboard(true);
      }
    }
  }, [isPuzzleComplete]);

  /* on validTimeSubmit change */
  useEffect(() => {
      /* only update sr on first play */
      if (validTimeSubmit) {
        updateSkillRating();
      }
  }, [validTimeSubmit])

  /* when the direction is changed */
  useEffect(() => {
    /* exit early if crossword not loaded */
    if (!crossword) {
      return
    }

    /* select a word with our new direction */
    var newSelection = findRelevantWord(crossword.words, selectedCell.x, selectedCell.y)
    /* update our selectedWord */
    setSelectedWord({ 
      selectedWordNumber: newSelection.number, 
      selectedWordDirection: newSelection.direction,
      hint: newSelection.hint,
      cells: newSelection.cells
    });
  }, [direction])

  /* on change of help menu visibility */
  useEffect(() => {

    /* helper */
    function handleClickOutside(event) {
      if (helpButtonRef.current && !helpButtonRef.current.contains(event.target) && 
          (!helpMenuRef.current || !helpMenuRef.current.contains(event.target))) {
        setHelpMenuVisible(false);
      }
    }

    /* close menu if detect outside click */
    if (isHelpMenuVisible) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isHelpMenuVisible]); 

  /* when rating is adjusted, update recent games */
  useEffect(() => {
    if (ratingAdjustment !== -2) {
      updateRecentGames();
    }
  }, [ratingAdjustment]); 

  /* forfeit match on leave */
  useEffect(() => {
    if (!matchId) return; /* single player */

    const handleUnload = () => {
      fetch(`${apiUrl}/multiplayer/match/forfeit`, {
        method: 'POST',
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`
        },     
        body: JSON.stringify({
          matchUuid: matchId,
          username: user.username
        })
      })
      .catch(err => console.error("Forfeit error:", err));
    };

    window.addEventListener('beforeunload', handleUnload);

    return () => {
      window.removeEventListener('beforeunload', handleUnload);
    };
  }, [matchId, user]);

  /* =================== HANDLERS =================== */
  /* handle focusing a cell */
  const handleCellFocus = (x, y) => {
    if (selectedCell.x === x && selectedCell.y === y) {
      setDirection(prevDirection => 1 - prevDirection);
    } else {
      setSelectedCell({ x, y });
    }
  };

  /* handle updates to the userGrid */
  const handleCellUpdate = (rowIndex, cellIndex, newValue) => {
    /* set a new user grid with the particular square modified */
    setUserGrid(currentGrid => {
      return currentGrid.map((row, rIdx) => {
        if (rIdx === rowIndex) {
          return row.map((cell, cIdx) => {
            if (cIdx === cellIndex) {
              return newValue;
            }
            return cell;
          });
        }
        return row;
      });
    });
  };  

  /* handle closing banner */
  const handleCloseBanner = () => {
    setIsPuzzleComplete(false);
    setShowLeaderboard(false);
  };

  /* =================== VISUAL =================== */
  return (
    <div className="main-container">
      {isMobile ? (
        <div className='mobile-ultimate-container'>
          {crossword ? (
            <>
              {!submissionLoading && showLeaderboard && (
                <PuzzleComplete
                  onClose={handleCloseBanner}
                  crossword={crossword}
                  puzzleId={crossword._id}
                  timer={time}
                  didSubmit={validTimeSubmit}
                  ratingAdjustment={ratingAdjustment}
                />
              )}

              {showMatchResults && (
                <MatchComplete
                  matchUuid={matchId}
                  username={user.username}
                  isMobile={isMobile}
                  onClose={() => setShowMatchResults(false)}
                />
              )}
              
              <div className='mobile-back-timer-header'>
                <button className='mobile-back-button' onClick={() => navigate('/play')}>
                  <i className='bx bxs-left-arrow'></i>
                </button>           

                <div className='mobile-timer'>{formatTime(time)}</div>

                <button ref={helpButtonRef} className='mobile-help-button' onClick={toggleHelpMenu}>
                  <i className='bx bxs-donate-heart'></i>
                </button>  

                {isHelpMenuVisible && (
                  <ul
                    ref={helpMenuRef}
                    className="mobile-help-menu"
                    style={{
                      top: `${menuPosition.top + (isMobile ? 20 : 0)}px`, 
                      left: `${menuPosition.left - (isMobile ? 160 : 0)}px`, 
                    }}
                  >
                    <li className="mobile-help-menu-warning">YOUR LEADERBOARD TIME WILL NOT BE SUBMITTED IF USED</li>
                    <li onClick={revealSquare} className="mobile-help-item">Reveal Square</li>
                    <li onClick={revealWord} className="mobile-help-item">Reveal Word</li>
                    <li onClick={revealPuzzle} className="mobile-help-item">Reveal Puzzle</li>
                  </ul>
                )}

              </div>

              <div className="grid-container">
                <CrosswordGrid grid={userGrid} 
                  selectedWord={selectedWord}
                  selectedCell={selectedCell}
                  numberedCells={numberedCells}
                  onCellUpdate={handleCellUpdate} 
                  onCellFocus={handleCellFocus} 
                  onNextCell={onNextCell}
                  onPrevCell={onPrevCell}
                  handleKeyDown={handleKeyDown}
                  blackEditable={false}
                  canFocus={true}
                  enforceBlack={true}
                  allowDashes={false}
                  isMobile={isMobile}
                />
              </div>

              {selectedWord.hint && (
                <div className='mobile-hint'>
                  {selectedWord.hint}
                </div>
              )}

              <CustomKeyboard
                onKeyPress={(key) => handleKeyDown({ key }, selectedCell.y, selectedCell.x)}
                hasEnter={true}
                isCreate={false}
                isEditing={false}
              />
            </>
          ) : (
            /* Loading Screen */
            <div className="loading-container" style={{ flex: 1 }}>
              <div className="loading-message">
                Loading crossword...
              </div>
            </div>
          )}
      </div>
      ) : (
        <div>
          {crossword ? (
            <>
              {!submissionLoading && showLeaderboard && (
                <PuzzleComplete
                  onClose={handleCloseBanner}
                  crossword={crossword}
                  puzzleId={crossword._id}
                  timer={time}
                  didSubmit={validTimeSubmit}
                  ratingAdjustment={ratingAdjustment}
                />
              )}

              {showMatchResults && (
                <MatchComplete
                  matchUuid={matchId}
                  username={user.username}
                  isMobile={isMobile}
                  onClose={() => setShowMatchResults(false)}
                />
              )}

              <div className='ultimate-container'>
              <div className='crossword-container'>
                <div className='back-timer-header'>
                  <button className='back-button' onClick={() => navigate('/play')}>
                    <i className='bx bxs-left-arrow'></i>
                  </button>           

                  <div className='timer'>{formatTime(time)}</div>

                  <button ref={helpButtonRef} className='help-button' onClick={toggleHelpMenu}>
                    <i className='bx bxs-donate-heart'></i>
                  </button>  

                  {isHelpMenuVisible && (
                    <ul ref={helpMenuRef} className="help-menu" style={{ top: `${menuPosition.top}px`, left: `${menuPosition.left}px` }}>
                      <li className="help-menu-warning">YOUR LEADERBOARD TIME WILL NOT BE SUBMITTED IF USED</li>
                      <li onClick={revealSquare} className="help-item">Reveal Square</li>
                      <li onClick={revealWord} className="help-item">Reveal Word</li>
                      <li onClick={revealPuzzle} className="help-item">Reveal Puzzle</li>
                    </ul>
                  )}
                </div>

                <CrosswordGrid grid={userGrid} 
                  selectedWord={selectedWord}
                  selectedCell={selectedCell}
                  numberedCells={numberedCells}
                  onCellUpdate={handleCellUpdate} 
                  onCellFocus={handleCellFocus} 
                  onNextCell={onNextCell}
                  onPrevCell={onPrevCell}
                  handleKeyDown={handleKeyDown}
                  blackEditable={false}
                  canFocus={true}
                  enforceBlack={true}
                  allowDashes={false}
                  isMobile={isMobile}
                />

                {selectedWord.hint && (
                  <div className='hint'>
                    {selectedWord.hint}
                  </div>
                )}

                </div>

                <div className='clues-container'>
                  <Clues crossword={crossword} selectedWord={selectedWord} direction={direction} />
                </div>
              </div>

            </>
          ) : (
            /* Loading Screen */
            <div className="loading-container" style={{ flex: 1 }}>
              <div className="loading-message">
                Loading crossword...
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
  
}

export default Play;