/* lib imports */
import React, { useState , useEffect, useRef} from 'react';
import { formatTime } from '../pages/Play';

/* context */
import AuthContext from '../context/AuthContext';

/* url's */
import { apiUrl, rootUrl } from '../config/environment';

/* style */
import '../style/match-complete.css';
import '../style/puzzle-complete.css';

/* the popup on competion of multiplayer match */
function MultiplayerMatchComplete({ matchUuid, username, isMobile, onClose }) {

  /* states */
  const [matchData, setMatchData] = useState(null);
  const [isPolling, setIsPolling] = useState(true);
  const pollIntervalRef = useRef(null);

  /* returns the leftover milliseconds of a leaderboard time */
  function getMilliseconds(time) {
    return time % 1000;
  }

  /* fetch details of match */
  const fetchMatchData = async () => {
    try {
      const res = await fetch(`${apiUrl}/multiplayer/match/${matchUuid}`, {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${localStorage.getItem('authToken')}`
          }
      });            
      const data = await res.json();

      setMatchData(data);
      if (data.player1Time !== null && data.player2Time !== null) {
        setIsPolling(false);
      } else if (data.status !== 'IN_PROGRESS') {
        setIsPolling(false);
      }
    } catch (error) {
      console.error("Error fetching match data:", error);
      setIsPolling(false);
    }
  };

  /* poll for completion */
  useEffect(() => {
    fetchMatchData();

    pollIntervalRef.current = setInterval(() => {
      fetchMatchData();
    }, 5000);

    return () => {
      clearInterval(pollIntervalRef.current);
    };
  }, [matchUuid]);

  /* poll handling */
  useEffect(() => {
    if (!matchData) return;
    if (
      matchData.player1Time !== null && 
      matchData.player2Time !== null
    ) {
      clearInterval(pollIntervalRef.current);
      setIsPolling(false);
    } else if (matchData.status !== 'IN_PROGRESS') {
      clearInterval(pollIntervalRef.current);
      setIsPolling(false);
    }
  }, [matchData]);

  /* visual during fetch */
  if (!matchData) {
    return (
      <div className="mult-match-complete">
        <h2>Fetching match results...</h2>
      </div>
    );
  }

  /* unpack */
  const { player1, player2, player1Time, player2Time, status } = matchData;
  const isPlayer1 = (username === player1);

  /* message */
  let message = "";
  if (status === 'FORFEIT' || status === 'CANCELLED') {
    message = "Match ended prematurely.";
  } 
  else if (player1Time !== null && player2Time !== null) {
    if (player1Time < player2Time) {
      message = `${player1} wins!`;
    } else if (player2Time < player1Time) {
      message = `${player2} wins!`;
    } else {
      message = "It's a tie!";
    }
  } else {
    message = "Waiting for your opponent to finish...";
  }

  /* visual */
  return (
    <div className="overlay">

      {isMobile ? (
        <>
          <div className='mobile-scale-container'>
            <div className="mobile-mc-popup-banner">
            {player1Time === null || player2Time === null ? (
              <>
                <div className='mobile-mc-header'>
                  MATCH IN PROGRESS!
                </div>
              </>
            ) : (
              <>
                <div className='mobile-mc-header'>
                  MATCH COMPLETE!
                </div>
              </>
            )}
            <div className='mobile-mc-message'>{message}</div>

            {/* player names and times */}
            <div className='mobile-timer-outer-container'>
                <div className='mobile-timer-container'>
                  <div className='player-username'>
                      {player1 || 'Unknown Player'}
                  </div>  
                  <div className='mc-timer-box'>              
                    {player1Time === 9999999 ? (
                          <>
                              <button className='pc-help-button'>
                                  <i className='bx bxs-donate-heart'></i>
                              </button>    
                          </>
                      ) : player1Time === null ? (
                        <div className='mc-timer-banner'>--:--</div>
                      ) : (
                          <>
                              <div className='mc-timer-banner'>{formatTime(player1Time)}</div>
                              <span className="mc-milliseconds">
                                  {getMilliseconds(player1Time).toString().padStart(3, '0')}
                              </span>
                          </>
                      )}
                  </div>
                </div>

                <div className='mobile-timer-container'>
                  <div className='player-username'>
                      {player2 || 'Unknown Player'}
                  </div>  
                  <div className='mc-timer-box'>              
                      {player2Time === 9999999 ? (
                          <>
                          <button className='pc-help-button'>
                              <i className='bx bxs-donate-heart'></i>
                          </button>    
                      </>                    
                      ) : player2Time === null ? (
                          <div className='mc-timer-banner'>--:--</div>
                      ) : (
                        <>
                            <div className='mc-timer-banner'>{formatTime(player2Time)}</div>
                            <span className="mc-milliseconds">
                                {getMilliseconds(player2Time).toString().padStart(3, '0')}
                            </span>
                        </>
                    )}
                  </div>
                </div>
            </div>

            <button className='mobile-mc-close-button' onClick={onClose}>Close</button>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="mc-popup-banner">
          {player1Time === null || player2Time === null ? (
            <>
              <div className='mc-header'>
                MATCH IN PROGRESS!
              </div>
            </>
          ) : (
            <>
              <div className='mc-header'>
                MATCH COMPLETE!
              </div>
            </>
          )}
          <div className='mc-message'>{message}</div>

          {/* player names and times */}
          <div className='timer-outer-container'>
              <div className='timer-container'>
                <div className='player-username'>
                    {player1 || 'Unknown Player'}
                </div>  
                <div className='mc-timer-box'>              
                  {player1Time === 9999999 ? (
                        <>
                            <button className='pc-help-button'>
                                <i className='bx bxs-donate-heart'></i>
                            </button>    
                        </>
                    ) : player1Time === null ? (
                      <div className='mc-timer-banner'>--:--</div>
                    ) : (
                        <>
                            <div className='mc-timer-banner'>{formatTime(player1Time)}</div>
                            <span className="mc-milliseconds">
                                {getMilliseconds(player1Time).toString().padStart(3, '0')}
                            </span>
                        </>
                    )}
                </div>
              </div>

              <div className='timer-container'>
                <div className='player-username'>
                    {player2 || 'Unknown Player'}
                </div>  
                <div className='mc-timer-box'>              
                    {player2Time === 9999999 ? (
                        <>
                        <button className='pc-help-button'>
                            <i className='bx bxs-donate-heart'></i>
                        </button>    
                    </>                    
                    ) : player2Time === null ? (
                        <div className='mc-timer-banner'>--:--</div>
                    ) : (
                      <>
                          <div className='mc-timer-banner'>{formatTime(player2Time)}</div>
                          <span className="mc-milliseconds">
                              {getMilliseconds(player2Time).toString().padStart(3, '0')}
                          </span>
                      </>
                  )}
                </div>
              </div>
          </div>

          <button className='mc-close-button' onClick={onClose}>Close</button>
        </div>
          </>
        )}
    </div>
  );
}

export default MultiplayerMatchComplete;
