/* lib imports */
import React, { useContext, useEffect, useState } from 'react';
import AuthContext from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';

/* url's */
import { apiUrl, rootUrl } from '../config/environment';

/* style */
import '../style/leaderboard.css';

/* leaderboard */
function Leaderboard() {

  /* get user */
  const { user } = useContext(AuthContext);

  /* create history object for navigation */
  const navigate = useNavigate(); 

  /* mobile handling */
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
  };
  useEffect(() => {
      window.addEventListener('resize', handleResize);
      return () => {
      window.removeEventListener('resize', handleResize);
      };
  }, []);

  /* store leaderboard entries */
  const [leaderboardEntries, setLeaderboardEntries] = useState([]);
  /* fetch users on mount */
  useEffect(() => {
    async function fetchLeaderboardEntries() {
        try {
          const response = await fetch(`${apiUrl}/leaderboard/ultimate-leaderboard`, {
              method: 'GET',
              headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${localStorage.getItem('authToken')}`
              }
          });
  
          if (!response.ok) {
            // Parse the response JSON to extract detailed error information
            const errorData = await response.json();
            
            // Build a detailed error message from the response
            const errorMessage = `HTTP error! Status: ${response.status} - ${errorData.message || 'Unknown error'} - ${errorData.suggestion || ''}`;
            const errorDetails = `Error: ${errorData.error || 'No error message'}\nStack: ${errorData.stack || 'No stack trace'}\nName: ${errorData.name || 'Unknown'}\nCode: ${errorData.code || 'No error code'}`;
  
            throw new Error(`${errorMessage}\n\nDetails:\n${errorDetails}`);
          }
  
          // Parse the JSON response
          const data = await response.json();
          
          // Update the state with the fetched data
          setLeaderboardEntries(data);
          console.log(data)
        } catch (error) {
          console.error('Error fetching leaderboard data:', error);
        }
      }
  
      fetchLeaderboardEntries();
    }, []);

  /* visual */
  if (isMobile) {
    return (
        <div className='under-construction'>
          <button className='lb-back-button' onClick={() => navigate('/')}>
            <i className='bx bxs-left-arrow'></i>
          </button>   
          Mobile under construction! Visit this page on a wider screen for a preview
        </div>    
    );
  } else {
    return (
        <div>

            {/* header */}
            <div className='lb-header-container'>

              <button className='lb-back-button' onClick={() => navigate('/')}>
                <i className='bx bxs-left-arrow'></i>
              </button>      
              <div className='lb-header'> LEADERBOARD </div>
            </div>

            {/* entries table */}
            <div className='lb-table-container'>

              <div className='lb-table-header'>
                <div className='rank-header'>RANK</div>
                <div className='username-header'>USERNAME</div>
                <div className='sr-header'>SKILL RATING</div>
              </div>

              {leaderboardEntries.map((entry, index) => (
                <div
                  className={`lb-entry ${index === 0 ? 'gold' : index === 1 ? 'silver' : index === 2 ? 'bronze' : ''}`}
                  key={index}
                >
                  <div className='entry-rank-number'> 
                    # {index + 1}
                  </div>
                  <div className='entry-username'>
                    {entry.username}
                  </div>
                  <div className='entry-skill-rating'>
                    {entry.skillRating}
                  </div>
                </div>
              ))}
            </div>
        </div>    
    );
  }
}

export default Leaderboard;
