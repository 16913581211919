// components/MultiplayerMatchComplete.jsx
import React, { useEffect, useState, useRef } from 'react';

/* url's */
import { apiUrl, rootUrl } from '../config/environment';

/* style */
import '../style/match-complete.css';

function MultiplayerMatchComplete({ matchUuid, username, onClose }) {
  const [matchData, setMatchData] = useState(null);
  const [isPolling, setIsPolling] = useState(true);
  const pollIntervalRef = useRef(null);

  // function to fetch match details
  const fetchMatchData = async () => {
    try {
      const res = await fetch(`${apiUrl}/multiplayer/match/${matchUuid}`);
      const data = await res.json();

      setMatchData(data);
      // if both player times are present or match status is DONE/ FORFEIT / CANCELLED
      if (data.player1Time !== null && data.player2Time !== null) {
        setIsPolling(false);
      } else if (data.status !== 'IN_PROGRESS') {
        // If match is CANCELLED or FORFEIT or DONE
        setIsPolling(false);
      }
    } catch (error) {
      console.error("Error fetching match data:", error);
      setIsPolling(false);
    }
  };

  // Poll for opponent's completion (if needed)
  useEffect(() => {
    fetchMatchData(); // initial fetch

    pollIntervalRef.current = setInterval(() => {
      fetchMatchData();
    }, 3000); // poll every 3s

    return () => {
      clearInterval(pollIntervalRef.current);
    };
  }, [matchUuid]);

  // If we detect both have finished or match is CANCELLED, stop polling
  useEffect(() => {
    if (!matchData) return;
    if (
      matchData.player1Time !== null && 
      matchData.player2Time !== null
    ) {
      clearInterval(pollIntervalRef.current);
      setIsPolling(false);
    } else if (matchData.status !== 'IN_PROGRESS') {
      clearInterval(pollIntervalRef.current);
      setIsPolling(false);
    }
  }, [matchData]);

  if (!matchData) {
    return (
      <div className="mult-match-complete">
        <h2>Fetching match results...</h2>
      </div>
    );
  }

  const { player1, player2, player1Time, player2Time, status } = matchData;

  // figure out who is who
  const isPlayer1 = (username === player1);

  // Format times
  const formatTime = (ms) => {
    if (ms === null) return "--:--";
    const minutes = Math.floor(ms / 60000);
    const seconds = Math.floor((ms % 60000) / 1000);
    return `${minutes}:${seconds.toString().padStart(2, '0')}`;
  };

  // Decide message
  let message = "";
  if (status === 'FORFEIT' || status === 'CANCELLED') {
    message = "Match ended prematurely.";
  } 
  else if (player1Time !== null && player2Time !== null) {
    // both finished
    if (player1Time < player2Time) {
      message = `Winner: ${player1}!`;
    } else if (player2Time < player1Time) {
      message = `Winner: ${player2}!`;
    } else {
      message = "It's a tie!";
    }
  } else {
    // Opponent not done or still in progress
    message = "Waiting for your opponent to finish...";
  }

  return (
    <div className="mult-match-complete">
      <h2>Multiplayer Results</h2>
      <p>Match ID: {matchUuid}</p>
      <p>Status: {status}</p>

      <div className="match-times">
        <div>
          <strong>{player1}</strong>: {formatTime(player1Time)}
        </div>
        <div>
          <strong>{player2}</strong>: {formatTime(player2Time)}
        </div>
      </div>

      <h3>{message}</h3>

      <button onClick={onClose}>Close</button>
    </div>
  );
}

export default MultiplayerMatchComplete;
